.select-dropdown{
    max-width: 100px;
    max-height: 150px;
    overflow-y: auto;
}

.home{
    padding-bottom: 300px;
}


.end{
    margin-bottom: 50px;
}


li{
    margin-bottom: 20px;
}

#key{
  width: 300px;
  height: 100px;
}

#text{
  width: 300px;
  height: 100px;
}
