/* ThreeD.css */
/* Make the canvas fill the entire screen */
canvas {
    width: 100%;
    height: 100vh;
    display: block; /* Removes any default margin/padding */
  }

  .form-bottom {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }
  input {
    margin: 5px;
  }
  button {
    margin-top: 10px;
  }